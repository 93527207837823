<template>
  <div>
    <div class="mainContent">
      <a-form-model :layout="layout" style="width:100%;" ref="ruleForm" :model="form">
        <a-col :sm="24" :md="12" :lg="8" :xl="6">
          <a-form-model-item label="日志查询" colon :labelCol='labelcol' :wrapperCol="wrappercol" prop="message">
            <a-input v-model="form.message" placeholder="请输入日志内容..." />
          </a-form-model-item>
        </a-col>


          <a-col :sm="24" :md="12" :lg="8" :xl="6">
            <a-form-model-item label="站点类型" :labelCol='labelcol' :wrapperCol="wrappercol" prop="site_type">
              <a-select v-model="form.site_type" placeholder="请选择站点类型..." >
                <a-select-option value="">
                  全部
               </a-select-option>
                <a-select-option value="self">
                  self
                </a-select-option>
                <a-select-option value="stripe">
                  stripe
                </a-select-option>
                <a-select-option value="paypal">
                  paypal
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>

          <a-col :sm="24" :md="12" :lg="8" :xl="6"  >
            <a-form-model-item label="开始日期" :labelCol='labelcol' :wrapperCol="wrappercol" >
              <a-date-picker
              v-model="form.start_time"
              :show-time="{defaultValue: moment(new Date(), 'YYYY-MM-DD HH:mm:ss').endOf('day') }" format="YYYY-MM-DD HH:mm:ss" @change="onChangeStart" @ok="onOk"/>
            </a-form-model-item>
          </a-col>
          <a-col :sm="24" :md="12" :lg="8" :xl="6"  >
            <a-form-model-item label="结束日期" :labelCol='labelcol' :wrapperCol="wrappercol" >
              <a-date-picker
              v-model="form.end_time"
              format="YYYY-MM-DD HH:mm:ss"
              :show-time="{ defaultValue: moment(new Date(), 'YYYY-MM-DD HH:mm:ss').endOf('day') }" @change="onChangeEnd"/>
            </a-form-model-item>
          </a-col>

          <a-col :sm="24" :md="12" :lg="8" :xl="6" class="lyq_flex_grow">
            <a-form-model-item style="text-align:right;">
              
              <a-button type="primary" @click="search">
                搜索
              </a-button>
              <a-button @click="reset('ruleForm')">重置</a-button>

            </a-form-model-item>
          </a-col>
      </a-form-model>
    </div>
    <!-- <div class="mainContent" style="display: flex;justify-content: flex-end;">
      <a-button type="primary" @click="onAdd">
        添加用户
      </a-button>
    </div> -->
    <div class="mainContent" >

      <div class="tableHeader">
        <div class="title">查询表格</div>
        
        <div class="operate">

          <a-tooltip placement="top">
            <template slot="title">
              <span>下载</span>
            </template>
            <a class="ant-dropdown-link" style="display:block;margin-right:10px;" @click="start" :disabled="!selectedRowKeys.length > 0">
              <a-icon type="vertical-align-bottom" />
            </a>
          </a-tooltip>

          <a-tooltip placement="top">
            <template slot="title">
              <span>表格大小</span>
            </template>
            <a-dropdown :trigger="['click']" style="margin-right:10px;">
              <a class="ant-dropdown-link" style="display:block">
                <a-icon type="column-height" />
              </a>
              <a-menu slot="overlay">
                <a-menu-item>
                  <a @click="tableSize='default'">默认</a>
                </a-menu-item>
                <a-menu-item>
                  <a @click="tableSize='middle'">中等</a>
                </a-menu-item>
                <a-menu-item>
                  <a @click="tableSize='small'">紧凑</a>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </a-tooltip>

        </div>
      </div>

      <a-table
        :size="tableSize"
        :scroll="{ x: 800 }"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :columns="columns"
        :row-key="record => record.id"
        :data-source="data"
        :pagination="pagination"
        :loading="loading"
        bordered
        @change="handleTableChange"
      >

        <!-- 状态单元格 -->
        <!-- <template slot="data" slot-scope="data,record">
          <div style='white-space:nowrap;overflow:hidden;text-overflow: ellipsis'>{{record.data}}</div>
        </template> -->
        <!-- 操作单元格 -->
        <template slot="操作" slot-scope="data">
              <span>
                <a-button size="small" type="primary"  @click="onDetails(data)">查看详情</a-button>
              </span>
        </template>
      </a-table>
    </div>

    <a-modal v-model="visible" title="查看详情" @ok="visible=false" width='800px'>
      <a-descriptions
      title="详细信息"
      bordered
      :column="{ xxl: 4, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }"
    >
      <a-descriptions-item label="消息名称" :span="2">
        {{DetailsData.message}}
      </a-descriptions-item>
 
      <a-descriptions-item label="站点类型" :span="1">
        {{DetailsData.site_type}}
      </a-descriptions-item>
      <a-descriptions-item label="创建时间" :span="1">
        {{DetailsData.create_time}}
      </a-descriptions-item>

      <a-descriptions-item label="日志数据" :span="2">

          {{DetailsData.data}}

      </a-descriptions-item>
    </a-descriptions>
    </a-modal>

  </div>
</template>
<script>
import {getLogsList} from "@/axios/logs.js"
import moment from 'moment';
import {myMixin} from "@/static/js/mixin.js"
const columns = [//列描述
  {
    align:'center',
    title: '消息名称',
    dataIndex: 'message',
  },
  
  {
    align:'center',
    title: '站点类型',
    dataIndex: 'site_type',
    
  },
  {
    ellipsis: true,
    align:'center',
    title: '日志数据',
    dataIndex: 'data',
    scopedSlots: { customRender: 'data', },
    width:'40%',
  },
  {
    align:'center',
    title: '创建时间',
    dataIndex: 'create_time',
  },

  {
    align:'center',
    title: '操作',
    // dataIndex: 'status',
    scopedSlots: { customRender: '操作', },
  },

];
export default {
  filters: {

    statusFilter(status) {
      const statusMap = {
        self:'自建站',
        stripe :'stripe',
        paypal:'paypal',
      }

      return statusMap[status]

    },
  },
  mixins: [myMixin],
  data() {

    return {
      visible:false,
      DetailsData:[],//错误信息
      
      form: {
        start_time:'',
        end_time: '',
        site_type: '',
        message:'',
      },

      data: [],//数据
      selectedRowKeys: [],//选择的表格
      pagination: {
        showQuickJumper:true,//是否快速跳转某页
        showSizeChanger:true,//是否可以改变条数
        pageSizeOptions:['10', '20', '50', '100','200'],
        showTotal:(total, range) => `当前显示第${range[0]}-${range[1]}，总条数：${total} `
      },//分页器
      loading: false,//是否加载中
      btnLoading:false,
      columns,//列描述
      tableSize:'default',//表格尺寸
      ordersTotal:{
        total:'',
        success_num:'',
        success_amount:'',
      },
    }
    
  },
  created(){
    this.form.start_time=this.moment(new Date()).startOf('day').format('YYYY-MM-DD HH:mm:ss')
    this.form.end_time=this.moment(new Date()).endOf('day').format('YYYY-MM-DD HH:mm:ss')

    let that=this
    this.fetch();
  },
  mounted() {

    
  },
  methods:{
    onOk(){},
      moment,
      search(){//搜索
        this.pagination.current =1;
        this.fetch({page:1,page_size:this.pagination.pageSize});
      },
      onChangeStart(date, dateString) {//时间 
        this.form.start_time=dateString;

      },
      onChangeEnd(date, dateString) {//时间 
        this.form.end_time=dateString;

      },
      reset(formName){
        this.$refs[formName].resetFields();
        this.pagination.current =1;
        this.fetch({page:1,page_size:this.pagination.pageSize});
      },
      onSelectChange(selectedRowKeys) {//选择表格
        this.selectedRowKeys = selectedRowKeys;
      },

      start() {//下载1
        console.log("导出",this.selectedRowKeys)
        // ajax request after empty completing
        setTimeout(() => {
          this.selectedRowKeys = [];
        }, 1000);
      },

      handleTableChange(pagination, filters, sorter) {
        //pagination:分页信息，filters：筛选信息，sorter：排序信息
        this.pagination = { ...pagination };
        console.log(this.pagination,pagination)
        this.fetch({
          page_size: pagination.pageSize,
          page: pagination.current,
          sortField: sorter.field,
          sortOrder: sorter.order,
          ...filters,
        });
      },

      async fetch (params = {}) {
        this.loading = true;
        let data=await getLogsList({page_size: 10,...params,...this.form})
        this.loading = false;
        const pagination = { ...this.pagination };
        pagination.total = data.data.total;

        this.data = data.data.list;
        this.pagination = pagination;
      },

      onDetails(data){
        this.DetailsData=data
        this.visible=true;
      },

    }
};
</script>
<style lang="scss" scoped>
@import "@/static/css/index.scss";


.ant-table td .greenBtn{background-color:#7ac141;border-color:#7ac141;}
.descriptions_label{padding:16px;text-align:center;}

::v-deep .ant-descriptions-bordered .ant-descriptions-item-label{word-break:keep-all;padding:16px;text-align: center;}
::v-deep .ant-descriptions-bordered .ant-descriptions-item-content{    word-break: break-all;}
</style>